<template>
  <Headline v-if="headline" type="h2" classes="subline2 mb-5 block text-center text-green_dark" :text="headline" />
  <div class="teaser relative mb-10" :class="type">
    <router-link :to="href" class="block">
      <div v-if="image && type !== 'discovery_box'"  class="teaser-image bg-white">
        <img :src="imageUrl" :alt="image.alternativeText" :title="image.caption" />
      </div>
      <div v-if="type === 'discovery_box'" class="teaser-bg-image absolute bottom-0 left-0 w-full h-auto">
        <img :src="discovery_box_image.src" alt="Discovery Box" />
      </div>
      <div class="teaser-body bg-white p-5">
        <div v-if="category_title" class="teaser-category flex justify-between mb-5">
          <span class="category-title font-small uppercase tracking-0.56 text-green_dark font-small font-bold">{{ category_title }}</span><Icons :type="category_icon" weight="light" classes="text-green_dark text-icons" />
        </div>
        <div class="teaser-title subline2 text-green_dark mb-4" :class="[
            type === 'discovery_box' ? 'flex justify-between' : ''
        ]">
          {{ title }}
          <Icons v-if="type === 'discovery_box'" type="angle-right" classes="text-pumpkin" />
        </div>
        <div class="teaser-text text-normal font-medium tracking--0.16 text-medium">
          {{ this.filteredText }}
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import filterService from "@/services/filterService";
import ApiService from "@/services/apiService";
import Headline from "@/components/Headline.vue";

const Icons = defineAsyncComponent(() => import('@/components/Icons'));

export default {
  name: "Teasers",
  components: {
    Headline,
    Icons
  },
  props: ["type", "href", "title", "text", "image", "category_title", "category_icon", "router_params", "headline"],
  data() {
    return {
      filteredText: '',
      imageUrl: '',
      discovery_box_image: {
        src: require('@/assets/img/discovery_box_teaser_background.svg')
      }
    }
  },
  mounted() {
    this.filteredText = filterService.truncate(this.$props.text, 75, '...');
    if(this.$props.image) {
      this.imageUrl = ApiService.getBaseUrl() + this.$props.image.url;
    }
  },
  created() {

  }
}
</script>

<style scoped lang="scss">
.teaser {

  box-sizing: border-box;

  &:last-child {
    margin-bottom: 0;
  }

  .teaser-image {
    height: 223px;
    overflow: hidden;
    position: relative;

    img {

      object-fit: cover;
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  &:hover {

    border: 4px solid var(--pumpkin-light);

    .teaser-body {

      padding: calc(1.25rem - 4px);
    }

    .teaser-image {
      img {
        left: -4px;
        top: -4px;
        width: calc(100% + 8px);
        max-width: none;
      }
    }
  }

  &.blog {

    box-shadow: 8px 8px 0 var(--mint-50-);
  }

  &.discovery_box {

    box-shadow: 8px 8px 0 var(--green-light);
    overflow: hidden;

    .teaser-body {
      background: var(--mint);
      padding-bottom: 114px;

      .teaser-title {

        font-size: var(--font-size-24);
      }
    }

    &:hover {

      .teaser-bg-image {

        left: -4px;
        width: calc(100% + 8px);
        bottom: -4px;
      }

      .teaser-body {
        padding-bottom: 110px;
      }
    }
  }
}
</style>
